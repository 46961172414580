import React, { useMemo } from 'react';
import {
  ChannelTypeInternal,
  GateFeatureTypeInternal,
  GateModeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../../hooks';
import { GateKindParam, useGate } from '../../../../../hooks/use-gate';
import { ChannelGateInterface, ChannelGateStateInterface, ChannelInterface } from '../../../../../types';
import {
  IconCirclePadlockClose,
  IconCirclePadlockOpen,
  IconCirclePadlockOpenFull,
} from '../../../../icons';
import { InitiateButton } from '../../../../initiate-button';
import { RollerControls } from '../../../../roller-controls';

interface ComponentProps {
  channel: ChannelInterface;
  isListItem?: boolean;
}

const stepByStepIcon = {
  1: <IconCirclePadlockClose />,
  2: <IconCirclePadlockOpen />,
  3: <IconCirclePadlockOpenFull />,
};

export const GateChannelControlBox: React.FC<ComponentProps> = ({ isListItem, channel }) => {
  const { handleAction, handleStepByStep } = useGate({ kind: GateKindParam.ChannelsDetails, channel });
  const { channelState } = useChannelsState();
  const state = channelState[channel.id] as ChannelGateStateInterface;

  const handleGateAction = (type: string) => handleAction(type);

  const isRollUpControls = useMemo(() => {
    if ((channel.data as ChannelGateInterface).supportedGateFeatures.length) {
      return (channel.data as ChannelGateInterface).supportedGateFeatures.includes(GateFeatureTypeInternal.SetDir);
    }

    return (channel.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp;
  }, [channel.data]);

  const isStepByStep = useMemo(() => {
    return (channel.data as ChannelGateInterface).gateMode === GateModeInternal.StepByStep;
  }, [(channel.data as ChannelGateInterface).gateMode]);

  const isQuickControl = useMemo(() => {
    return (channel.data as ChannelGateInterface).isQuickControl;
  }, [(channel.data as ChannelGateInterface).isQuickControl]);

  const gateStep = useMemo(() => {
    if (!state.position) return 3;
    return state.position === 100 ? 1 : 2;
  }, [state]);

  return (
    <>
      {isRollUpControls ? (
        <RollerControls handleAction={handleGateAction} isListItem={isListItem} type={ChannelTypeInternal.Gate} />
      ) : isStepByStep && isQuickControl ? (
        <InitiateButton isOn={false} onClickControl={handleStepByStep} isSmall isChannelItem checkStatus>
          {stepByStepIcon[gateStep]}
        </InitiateButton>
      ) : null}
    </>
  );
};
