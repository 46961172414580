import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  DashboardItemType,
  ModifyGroupItemInMultipleGroupsMutation,
  ModifyGroupItemInMultipleGroupsMutationVariables,
  UserPermissionType,
} from '../../../data-access/gql-types/graphql';
import { MODIFY_GROUP_ITEM_IN_MULTIPLE_GROUPS } from '../../../data-access/mutations/groups';
import { useApi, useBackdropContext, useInstallation } from '../../../hooks';
import { useGroupErrors } from '../../../hooks/backend-errors/use-group-errors';
import { useRefetchData } from '../../../hooks/refresh-data';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { toastError, toastSuccess } from '../../../utils/toast';
import { GroupItem } from '../types';

interface HookParams {
  itemId: string;
  itemType: DashboardItemType;
}

export const useModifyMultipleGroups = ({ itemId, itemType }: HookParams) => {
  const { t } = useTranslation('common');
  const [groupPopup, setGroupPopup] = useState<boolean>(false);
  const [groupList, setGroupList] = useState<GroupItem[]>([]);
  const { groups } = useApi();
  const { permissions } = usePermissionsContext();
  const { selectedInstallationId } = useInstallation();
  const { refetchDashboard } = useRefetchData();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [modifyGroup] = useMutation<
    ModifyGroupItemInMultipleGroupsMutation,
    ModifyGroupItemInMultipleGroupsMutationVariables
  >(MODIFY_GROUP_ITEM_IN_MULTIPLE_GROUPS);
  const { handleErrors } = useGroupErrors();

  useEffect(() => {
    const canOperateOnPublicGroups = permissions[UserPermissionType.AddRemoveChannelForMultiplePublicGroups];

    const list = groups
      .filter((g) => (!canOperateOnPublicGroups ? !g.group.isPublicGroup : g.id))
      .map((group) => ({
        id: group.id,
        name: group.group.name,
        checked: !!group.userGroupItems.find((x) => x.itemId === itemId),
      }));

    setGroupList(list);
  }, [groupPopup, groups, permissions]);

  const toggleGroup = (id: string) => {
    setGroupList((prev) => {
      const foundIndex = prev.findIndex((x) => x.id === id);
      if (foundIndex !== -1) prev[foundIndex].checked = !prev[foundIndex].checked;
      return [...prev];
    });
  };

  const handleSaveGroup = () => {
    turnOnBackdrop();
    const addFiltered = groups.filter((group) => !group.userGroupItems.find((x) => x.itemId === itemId));
    const removeFiltered = groups.filter((group) => group.userGroupItems.find((x) => x.itemId === itemId));

    const groupIdsToAdd = groupList
      .filter((group) => group.checked && addFiltered.find((x) => x.id === group.id))
      .map((x) => x.id);
    const groupIdsToRemove = groupList
      .filter((group) => !group.checked && removeFiltered.find((x) => x.id === group.id))
      .map((x) => x.id);

    modifyGroup({
      variables: {
        input: {
          installationId: selectedInstallationId,
          itemId,
          itemType,
          groupIdsToAdd,
          groupIdsToRemove,
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();

        if (data.modifyGroupItemInMultipleGroups.result?.ok) {
          refetchDashboard();
          setGroupPopup(false);
          toastSuccess({ content: t('saved') });
        } else {
          handleErrors(data.modifyGroupItemInMultipleGroups.errors || []);
        }
      },
      onError: () => {
        turnOffBackdrop();
        toastError({ content: t('errors.somethingWentWrong') });
      },
    });
  };

  return {
    groupPopup,
    groupList,
    setGroupPopup,
    handleSaveGroup,
    toggleGroup,
  };
};
