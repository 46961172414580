import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import {
  OnOptimizerConfigurationTaintedPayloadSubscription,
  OnOptimizerConfigurationTaintedPayloadSubscriptionVariables,
} from '../../../../data-access/gql-types/graphql';
import {
  ON_OPTIMIZER_CONFIGURATION_TAINTED_PAYLOAD,
} from '../../../../data-access/subscriptions/lavva-devices';
import { useInstallation } from '../../../../hooks';
import { ChannelSubscriptionParams } from '../types';

export const useOptimizerSubscriptions = ({ channelId }: ChannelSubscriptionParams): void => {
  const { skipLavvaFetch } = useInstallation();
  const { data: optimizerConfigurationTainted } = useSubscription<
    OnOptimizerConfigurationTaintedPayloadSubscription,
    OnOptimizerConfigurationTaintedPayloadSubscriptionVariables
  >(ON_OPTIMIZER_CONFIGURATION_TAINTED_PAYLOAD, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (optimizerConfigurationTainted) {
      console.log(
        'OPTIMIZER_CONFIGURATION_TAINTED_PAYLOAD',
        optimizerConfigurationTainted.onOptimizerConfigurationTaintedPayload,
      );
    }
  }, [optimizerConfigurationTainted]);
};
