import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Carousel, CarouselItem, Header, IconAdd, Page, Tabs } from '../../components';
import { Tip } from '../../components/tip';
import { TriggerNumberExceededDialog } from '../../components/trigger/trigger-number-exceeded-dialog';
import { ActionType, UserPermissionType } from '../../data-access/gql-types/graphql';
import { useApi, useDevicesAndChannels, useInstallation } from '../../hooks';
import { useRefetchData } from '../../hooks/refresh-data';
import useTriggers from '../../hooks/use-triggers';
import { usePermissionsContext } from '../../hooks/user-permissions';
import { ROUTES } from '../../routes';
import { tipKey, tipTranslation } from '../../types';
import * as storage from '../../utils/storage/lavva';
import CheckContentWrapper from '../check-content-wrapper';
import { useAddDevice } from '../device-add/hooks/use-add-device';
import { TriggerList } from './components/trigger-list';
import { UserActionList } from './components/user-action-list';

const permissionEnumForActionTab = {
  0: UserPermissionType.ActionsCreate,
  1: UserPermissionType.ActionsCreate,
  2: UserPermissionType.TriggersCreate,
};

const ActionList: React.FC = () => {
  const { t } = useTranslation('action');
  const history = useHistory();
  const { userActions: userActionData } = useApi();
  const { allTriggers, loading } = useTriggers();
  const { selectedInstallation, installationList } = useInstallation();
  const { channelList } = useDevicesAndChannels();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(Number(storage.getItem('lastActiveActionTab')));
  const [triggerNumberExceededOpen, setTriggerNumberExceededOpen] = useState<boolean>(false);
  const { addDevice } = useAddDevice();
  const { permissions } = usePermissionsContext();
  const { refetchTriggers } = useRefetchData();

  useEffect(() => {
    if (activeTabIndex === 2) {
      refetchTriggers();
    }
  }, [activeTabIndex, userActionData]);

  const channelListCount = useMemo(() => channelList.length, [channelList]);

  const handleAction = () => {
    if (channelListCount === 0 && permissions[UserPermissionType.DeviceAdd]) addDevice();
    else {
      switch (activeTabIndex) {
        case 0:
          history.push(ROUTES.ActionCreateBasic());
          break;
        case 1:
          history.push(ROUTES.ActionCreate());
          break;
        case 2:
          history.push(ROUTES.TriggerCreate());
          break;
        default:
          break;
      }
    }
  };

  const handleTabChange = (tabIndex: number) => {
    storage.setItem('lastActiveActionTab', String(tabIndex));
    setActiveTabIndex(tabIndex);
  };

  return (
    <Page
      isStickyHeader
      containerClassName={'page__container--flex-height p-t-16'}
      header={
        <>
          <Header title={t('actions')} installationLogoTitleHeight>
            {installationList.length !== 0 &&
              selectedInstallation !== undefined &&
              (activeTabIndex === 2 && allTriggers?.length >= 20 ? (
                <div onClick={() => setTriggerNumberExceededOpen(true)}>
                  <IconAdd />
                </div>
              ) : permissions[permissionEnumForActionTab[activeTabIndex]] ? (
                <button onClick={handleAction}>
                  <IconAdd />
                </button>
              ) : null)}
          </Header>
          {selectedInstallation && (
            <Tabs
              tabList={[
                { label: t('actionsType.basic') },
                { label: t('actionsType.advanced') },
                { label: t('actionsType.automations') },
              ]}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={handleTabChange}
              tabsType="pills"
              isDivider={false}
            />
          )}
        </>
      }
    >
      <CheckContentWrapper>
        <Carousel setActiveIndex={handleTabChange} activeIndex={activeTabIndex}>
          <CarouselItem>
            <UserActionList
              list={userActionData?.filter(
                (userAction) =>
                  userAction.action.actionType === ActionType.Basic ||
                  userAction.action.actionType === ActionType.Central,
              )}
              channelListCount={channelListCount}
              isAdvanced={false}
            />
          </CarouselItem>
          <CarouselItem>
            <UserActionList
              list={userActionData?.filter((userAction) => userAction.action.actionType === ActionType.Advanced)}
              channelListCount={channelListCount}
              isAdvanced
            />
          </CarouselItem>
          <CarouselItem>
            <TriggerList list={allTriggers} loading={loading} channelListCount={channelListCount} />
          </CarouselItem>
        </Carousel>
      </CheckContentWrapper>
      <TriggerNumberExceededDialog open={triggerNumberExceededOpen} setOpen={setTriggerNumberExceededOpen} />
      <Tip
        tip={t(tipTranslation[activeTabIndex])}
        learningKey={tipKey[activeTabIndex]}
        point={<IconAdd />}
        top={40}
        right={22}
      />
    </Page>
  );
};

export default ActionList;
