import { useMemo } from 'react';
import { InitiateButton } from '../../../../../../../components';
import { GateStateDirectionInternal } from '../../../../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../../../../hooks';
import { ChannelGateStateInterface, ChannelInterface } from '../../../../../../../types';
import { GateStepIcon } from '../../../gate-step-icon';

interface ComponentProps {
  channel: ChannelInterface;
  handleAction: () => void;
}

export const DefaultKindControl: React.FC<ComponentProps> = ({ channel, handleAction }) => {
  const { channelState } = useChannelsState();
  const state = channelState[channel.id] as ChannelGateStateInterface;

  const gateStep = useMemo(() => {
    const position = (channelState[channel.id] as ChannelGateStateInterface).position;
    if (!position) return 3;
    return position === 100 ? 1 : 2;
  }, [channelState[channel.id]]);

  return (
    <InitiateButton
      isOn={false}
      onClickControl={handleAction}
      active={[GateStateDirectionInternal.Opening, GateStateDirectionInternal.Closing].includes(state.direction)}
    >
      <GateStepIcon step={gateStep} />
    </InitiateButton>
  );
};
