import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import {
  ON_LIGHT_BRIGHTNESS_CHANGE,
  ON_LIGHT_COLOR_CHANGE,
  ON_LIGHT_TEMPERATURE_CHANGE,
  ON_LIGHT_TOGGLE_STATE_CHANGE,
  ON_REQUEST_ACK_RECEIVED,
} from '../../../../data-access/subscriptions/lavva-devices';
import { useInstallation } from '../../../../hooks';
import { useUpdateCommonLavvaState } from '../common-lavva-state';
import { ChannelSubscriptionParams } from '../types';
import { useLavvaDeviceLightUpdate } from './update';

export const useLightSubscriptions = ({ channelId }: ChannelSubscriptionParams): void => {
  const { updateLavvaLightState, updateLavvaLightBrightness, updateLavvaLightTemperature, updateLavvaLightColor } =
    useLavvaDeviceLightUpdate();
  const { onRequestAckReceived } = useUpdateCommonLavvaState();
  const { skipLavvaFetch } = useInstallation();
  const { data: lightToggleChange } = useSubscription(ON_LIGHT_TOGGLE_STATE_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: lightBrightnessChange } = useSubscription(ON_LIGHT_BRIGHTNESS_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: lightTemperatureChange } = useSubscription(ON_LIGHT_TEMPERATURE_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: lightColorChange } = useSubscription(ON_LIGHT_COLOR_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: ackReceived } = useSubscription(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (lightToggleChange) {
      updateLavvaLightState(lightToggleChange.onLightToggleStateChange);
    }
  }, [lightToggleChange]);

  useEffect(() => {
    if (lightBrightnessChange) {
      updateLavvaLightBrightness(lightBrightnessChange.onLightBrightnessChange);
    }
  }, [lightBrightnessChange]);

  useEffect(() => {
    if (lightTemperatureChange) {
      updateLavvaLightTemperature(lightTemperatureChange.onLightTemperatureChange);
    }
  }, [lightTemperatureChange]);

  useEffect(() => {
    if (lightColorChange) {
      updateLavvaLightColor(lightColorChange.onLightColorChange);
    }
  }, [lightColorChange]);

  useEffect(() => {
    if (ackReceived) onRequestAckReceived(ackReceived.onRequestAckReceived);
  }, [ackReceived]);
};
