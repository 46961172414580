import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { useGateSetAlertConfig, useGateSetSbsSignalTime } from '../../../../api/modules/gate/gate.hooks';
import { AlertConfigValue } from '../../../../api/modules/gate/gate.types';
import {
  ChannelTypeInternal,
  GateModeInternal,
  OnPartialConfigChangedSubscription,
  OnPartialConfigChangedSubscriptionVariables,
} from '../../../../data-access/gql-types/graphql';
import { ON_PARTIAL_CONFIG_CHANGED } from '../../../../data-access/subscriptions/lavva-devices';
import { useBackdropContext, useDevicesAndChannels, useInstallation } from '../../../../hooks';
import { useTimeout } from '../../../../hooks/timeout/use-timeout';
import { ChannelGateInterface } from '../../../../types';
import { timeToMilliseconds } from '../../../../utils/helpers';
import { toastSuccess } from '../../../../utils/toast';

export const useGateConfiguration = () => {
  const { t } = useTranslation('channel-settings');
  const { channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();
  const { channel } = useDevicesAndChannels({ channelId });
  const { skipLavvaFetch } = useInstallation();
  const { setChannelList } = useDevicesAndChannels();
  const { setTimeoutError, clearTimeoutError } = useTimeout();
  const setSbsSignalTime = useGateSetSbsSignalTime();
  const setGateSetAlertConfig = useGateSetAlertConfig();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const gateMode = useMemo(
    () => (channel?.data as ChannelGateInterface)?.gateMode,
    [(channel?.data as ChannelGateInterface)?.gateMode],
  );
  const { data: configurationData } = useSubscription<
    OnPartialConfigChangedSubscription,
    OnPartialConfigChangedSubscriptionVariables
  >(ON_PARTIAL_CONFIG_CHANGED, {
    variables: { channelId },
    skip: skipLavvaFetch || gateMode !== GateModeInternal.StepByStep,
  });

  useEffect(() => {
    if (configurationData?.onPartialConfigChanged) {
      clearTimeoutError();
      const { numericalProperties, channelId } = configurationData.onPartialConfigChanged;
      console.log('CONFIGURATION DATA', numericalProperties);

      const signalTime = numericalProperties.find((x) => x.property === 'SbsSignalTimeInMs');
      const barrierAlertEnabled = numericalProperties.find((x) => x.property === 'BarrierAlertEnabled');
      const misdirectionAlertEnabled = numericalProperties.find((x) => x.property === 'MisdirectionAlertEnabled');
      const externalSourceTriggeredAlertEnabled = numericalProperties.find(
        (x) => x.property === 'ExternalSourceTriggeredAlertEnabled',
      );
      const barrierTime = numericalProperties.find((x) => x.property === 'OpenedIntervalToNotifyInMs');

      setChannelList((prev) => {
        const temp = [...prev];
        const index = temp.findIndex((x) => x.id === channelId);
        if (index !== -1) {
          (temp[index].data as ChannelGateInterface) = {
            ...(temp[index].data as ChannelGateInterface),
            ...(signalTime?.value ? { sbsSignalTimeInMs: Number(signalTime.value) } : {}),
            ...(barrierAlertEnabled?.value ||
            barrierTime?.value ||
            misdirectionAlertEnabled?.value ||
            externalSourceTriggeredAlertEnabled?.value
              ? {
                  alertConfig: {
                    barrierAlertEnabled: barrierAlertEnabled?.value === 'True',
                    misdirectionAlertEnabled: misdirectionAlertEnabled?.value === 'True',
                    externalSourceTriggeredAlertEnabled: externalSourceTriggeredAlertEnabled?.value === 'True',
                    openedIntervalToNotifyInMs: Number(barrierTime?.value || 0),
                  },
                }
              : {}),
          };
        }

        return temp;
      });

      toastSuccess({ content: t('toast.editConfigurationSuccess') });
      turnOffBackdrop();
    }
  }, [configurationData]);

  const handleSaveSignalTime = (time: string) => {
    if (channel) {
      turnOnBackdrop();

      setSbsSignalTime.mutate(
        {
          channelId: channel.id,
          deviceId: channel.deviceId,
          value: timeToMilliseconds(time),
        },
        {
          onSuccess: () => {
            setTimeoutError();
          },
        },
      );
    }
  };

  const handleSaveAlert = (values: AlertConfigValue) => {
    if (channel) {
      turnOnBackdrop();

      setGateSetAlertConfig.mutate(
        {
          channelId: channel.id,
          value: values,
        },
        {
          onSuccess: () => {
            setTimeoutError();
          },
        },
      );
    }
  };

  return {
    gateMode,
    handleSaveSignalTime,
    handleSaveAlert,
  };
};
