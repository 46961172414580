import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { SelectOptionInterface } from '../../../../../components';
import { CheckboxesOptions } from '../../../../../components/checkbox-select';
import {
  AggregateVectorParameterTypeInternal,
  MeasurementChannelKind,
  MeasurementDataRangeInternal,
  MeterAggregatedMeasurementsQueryVariables,
  Query,
} from '../../../../../data-access/gql-types/graphql';
import { METER_AGGREGATED_MEASUREMENTS } from '../../../../../data-access/queries/meter';
import { useApi, useInstallation } from '../../../../../hooks';
import { ChannelMeterInterface, MeasurementRange } from '../../../../../types';
import { ChartDataItem } from '../index';
import { AnalysisHook, AnalysisHookParams } from '../types';
import { predictionRangesAvailable } from '../utils';
import { parameterColor, predictionParameters } from '../utils/helpers';

export const useMeterAnalysis = ({ channel }: AnalysisHookParams): AnalysisHook => {
  const { t } = useTranslation('channel-details');
  const { selectedInstallationId } = useInstallation();
  const { convertMeasurementToNumber, measurementMap } = useApi();
  const [aggregatedParameters, setAggregatedParameters] = useState<
    CheckboxesOptions<AggregateVectorParameterTypeInternal>[]
  >([]);
  const [activeMeasurementRange, setActiveMeasurementRange] = useState<MeasurementRange>(MeasurementRange.DayInHours);
  const [page, setPage] = useState<number>(0);
  const [getAggregatedMeasurements, { data, loading }] = useLazyQuery<Query, MeterAggregatedMeasurementsQueryVariables>(
    METER_AGGREGATED_MEASUREMENTS,
  );

  const supported = useMemo(() => {
    return (channel?.data as ChannelMeterInterface)?.supportedAggregateVectorParameters;
  }, [(channel?.data as ChannelMeterInterface)?.supportedAggregateVectorParameters]);

  const checkIfSupported = (param: AggregateVectorParameterTypeInternal) =>
    supported.includes(convertMeasurementToNumber(MeasurementChannelKind.Meter)('aggregates', param));

  useEffect(() => {
    if (supported.length && !aggregatedParameters.length && measurementMap) {
      setAggregatedParameters([
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ForwardActiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
          checked: true,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ForwardActiveEnergy),
        },
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ReverseActiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ReverseActiveEnergy),
        },
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ForwardReactiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ForwardReactiveEnergy),
        },
        {
          label: t(`energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.ReverseReactiveEnergy}`),
          value: AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.ReverseReactiveEnergy),
        },
        {
          label: t(
            `energyMeasurements.types.summary.${AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy}`,
          ),
          value: AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
          checked: false,
          disabled: !checkIfSupported(AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy),
        },
      ]);
    }
  }, [supported, t, convertMeasurementToNumber, measurementMap, aggregatedParameters]);

  const timeRanges: SelectOptionInterface<MeasurementRange>[] = useMemo(
    () => [
      { value: MeasurementRange.DayInHours, label: t('dateAbbreviation.hours') },
      { value: MeasurementRange.MonthInDays, label: t('dateAbbreviation.days') },
      { value: MeasurementRange.WeekInDays, label: t('dateAbbreviation.weeks') },
      { value: MeasurementRange.YearInMonths, label: t('dateAbbreviation.months') },
      { value: MeasurementRange.TenYearsInYears, label: t('dateAbbreviation.years') },
    ],
    [t],
  );

  const relativeActiveMeasurementRange = useMemo(() => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours: {
        return MeasurementDataRangeInternal.InHours;
      }
      case MeasurementRange.YearInMonths: {
        return MeasurementDataRangeInternal.InMonths;
      }
      case MeasurementRange.TenYearsInYears: {
        return MeasurementDataRangeInternal.InYears;
      }
      default: {
        return MeasurementDataRangeInternal.InDays;
      }
    }
  }, [activeMeasurementRange]);

  useEffect(() => {
    const aggregateVectorRequestItems = aggregatedParameters
      .filter((x) => !x.disabled && x.checked)
      .map((param) => ({
        type: param.value,
        range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
      }));

    if (
      aggregateVectorRequestItems.find((x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy) &&
      checkIfSupported(AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy) &&
      predictionRangesAvailable.includes(relativeActiveMeasurementRange)
    ) {
      aggregateVectorRequestItems.push({
        type: AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
        range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
      });
    }

    if (
      aggregateVectorRequestItems.find(
        (x) => x.type === AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
      ) &&
      checkIfSupported(AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy) &&
      predictionRangesAvailable.includes(relativeActiveMeasurementRange)
    ) {
      aggregateVectorRequestItems.push({
        type: AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
        range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
      });
    }

    getAggregatedMeasurements({
      variables: {
        installationId: selectedInstallationId,
        input: {
          channelId: channel.id,
          deviceId: channel.deviceId,
          indices: [0],
          aggregateVectorRequestItems,
        },
      },
    });
  }, [aggregatedParameters, relativeActiveMeasurementRange, supported]);

  const aggregatedMeasurements = useMemo(() => {
    return (data?.meterAggregatedMeasurements.data?.aggregateVectorMeasurements || []).filter((measurement) => {
      return !(predictionParameters.includes(measurement.type) && !measurement.values.length);
    });
  }, [data?.meterAggregatedMeasurements]);

  const prepareMappedMeasurementValue = (type: AggregateVectorParameterTypeInternal, value: number) => ({
    type,
    value: value / 1000,
    color: parameterColor[type],
  });

  const mappedChartData: ChartDataItem[] = useMemo(() => {
    const itemsData: ChartDataItem[] = [];

    if (aggregatedMeasurements.length) {
      const obj = orderBy(aggregatedMeasurements, (x) => x.values.length, 'desc').reduce((acc, item) => {
        acc[item.type] = item.values;
        return acc;
      }, {}) as Record<AggregateVectorParameterTypeInternal, number[]>;

      Object.entries(obj).forEach(([key, value]) => {
        value.map((val, i) => {
          if (!itemsData[i]?.values) {
            itemsData.push({
              values: [prepareMappedMeasurementValue(key as AggregateVectorParameterTypeInternal, val)],
            });
          } else {
            itemsData[i].values.push(prepareMappedMeasurementValue(key as AggregateVectorParameterTypeInternal, val));
          }
        });
      });

      return itemsData;
    }

    return [];
  }, [activeMeasurementRange, data?.meterAggregatedMeasurements]);

  const onClickActiveMeasurementRange = useCallback((value: MeasurementRange) => {
    setActiveMeasurementRange(value);
    setPage(0);
  }, []);

  return {
    data: data?.meterAggregatedMeasurements?.data,
    isLoading: loading,
    mappedChartData,
    onClickActiveMeasurementRange,
    setAggregatedParameters,
    aggregatedParameters,
    activeMeasurementRange,
    page,
    setPage,
    timeRanges,
  };
};
