import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSetInputTypesBatch } from '../../../../../api/modules/generic-input/generic-input.hooks';
import { InputSelect } from '../../../../../components';
import {
  ChannelTypeInternal,
  GenericInputBistableModeInternal,
  GenericInputMonostableModeInternal,
  GenericInputTypeInternal,
  VersionedFeatureType,
} from '../../../../../data-access/gql-types/graphql';
import { useBackdropContext, useDevicesAndChannels } from '../../../../../hooks';
import useVersionMap from '../../../../../hooks/api/version-map';
import { ChannelInterface } from '../../../../../types';
import { ChannelGenericInputInterface } from '../../../../../types/channel/generic-input';
import { toastInfo, toastSuccess } from '../../../../../utils/toast';
import {
  bistableModeForRequest,
  inputTypeForRequest,
  monostableModeForRequest,
} from '../../../../device-settings/device-inputs/utils';
import { InfoPageType } from '../../../types';
import Info from '../info';

interface ComponentProps {
  openInfoPage: (type: InfoPageType) => void;
  isUnderlineDown?: boolean;
  isUnderlineUp?: boolean;
}

export const InputsType: React.FC<ComponentProps> = ({ openInfoPage, isUnderlineDown, isUnderlineUp }) => {
  const { t } = useTranslation('configuration');
  const { t: td } = useTranslation('device-settings');
  const [genericInputs, setGenericInputs] = useState<ChannelInterface[]>([]);
  const [genericInputType, setGenericInputType] = useState<GenericInputTypeInternal>(GenericInputTypeInternal.Unknown);
  const { channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();
  const { channel, channelList, deviceList, setChannelList } = useDevicesAndChannels({ channelId });
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { mutate } = useSetInputTypesBatch();
  const { checkSupport } = useVersionMap();

  useEffect(() => {
    if (channel?.deviceId) {
      const inputs = channelList.filter(
        (x) => x.deviceId === channel.deviceId && x.data.type === ChannelTypeInternal.GenericInput,
      );

      setGenericInputs(inputs);

      if (inputs.length) {
        const allMonostable = inputs.every(
          (x) => (x.data as ChannelGenericInputInterface).genericInputType === GenericInputTypeInternal.Monostable,
        );

        if (allMonostable) {
          setGenericInputType(GenericInputTypeInternal.Monostable);
          return;
        }

        const allBistable = inputs.every(
          (x) => (x.data as ChannelGenericInputInterface).genericInputType === GenericInputTypeInternal.Bistable,
        );

        if (allBistable) setGenericInputType(GenericInputTypeInternal.Bistable);
      }
    }
  }, [channel?.deviceId, channelList]);

  const handleUpdateInputType = (value: GenericInputTypeInternal) => {
    const device = deviceList.find((x) => x.id === channel?.deviceId);

    const supported = checkSupport({
      feature: VersionedFeatureType.DeviceBindingsV2,
      supportKey: 'Core',
      currentVersion: device?.payload.currentFirmwareVersion,
    });

    if (!supported) toastInfo({ content: td('updateToUseFeature') });
    else {
      if (channel?.deviceId) {
        turnOnBackdrop();

        mutate(
          {
            deviceId: channel.deviceId,
            items: genericInputs.map((x) => ({
              channelId: x.id,
              genericInputType: inputTypeForRequest[value],
              ...(value === GenericInputTypeInternal.Monostable
                ? {
                    monostableMode:
                      monostableModeForRequest[
                        (x.data as ChannelGenericInputInterface).monostableMode ||
                          GenericInputMonostableModeInternal.Normal
                      ],
                  }
                : {}),
              ...(value === GenericInputTypeInternal.Bistable
                ? {
                    bistableMode:
                      bistableModeForRequest[
                        (x.data as ChannelGenericInputInterface).bistableMode || GenericInputBistableModeInternal.Normal
                      ],
                  }
                : {}),
            })),
          },
          {
            onSuccess: () => {
              toastSuccess({ content: t('editInputTypesBatchSuccess') });

              setChannelList((prev) => {
                const temp = [...prev];

                genericInputs.forEach((input) => {
                  const index = temp.findIndex((x) => x.id === input.id);

                  if (index !== -1) {
                    (temp[index].data as ChannelGenericInputInterface).genericInputType = value;
                  }
                });

                return [...temp];
              });

              turnOffBackdrop();
            },
            onError: () => {
              turnOffBackdrop();
            },
          },
        );
      }
    }
  };

  return (
    <>
      {genericInputs.length ? (
        <>
          {isUnderlineUp && <hr />}
          <Info title={t('inputType')} onClick={() => openInfoPage(InfoPageType.INPUT_TYPE)} />
          <InputSelect
            label={t('inputType')}
            value={genericInputType}
            onChange={handleUpdateInputType}
            options={[
              { label: t('monoStable'), value: GenericInputTypeInternal.Monostable },
              { label: t('biStable'), value: GenericInputTypeInternal.Bistable },
            ]}
          />
          {isUnderlineDown && <hr />}
        </>
      ) : null}
    </>
  );
};
