import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import c from 'classnames';
import { Header, NavHeader, Page, PopUpNav, SubmitButton, Switch, Tabs } from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { EditIcon } from '../../../components/edit-icon';
import { IconShare } from '../../../components/popup-nav/icons';
import { ChannelTypeInternal, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../hooks';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { ROUTES } from '../../../routes';
import { ChannelGateInterface } from '../../../types';
import { isDev, isLocalhost } from '../../../utils/helpers/environment';
import { pathnameWithParameters } from '../../../utils/location';
import { useShareRedirect } from '../../installation-share/hooks/use-share-redirect';
import { InfoPageType } from '../types';
import ConfigurationName from './components/configuration-name';
import { availableIcons } from './components/icon-edit/available-icons';
import Info from './components/info';
import InfoPage from './components/info-page';
import CoverConfiguration from './forms/cover';
import GateConfiguration from './forms/gate';
import LightConfiguration from './forms/light';
import MeasurementsConfiguration from './forms/measurements';
import OptimizerConfiguration from './forms/optimizer';
import SwitchConfiguration from './forms/switch';
import { useConfigurationForm } from './hooks/use-configuration-form';
import './index.scss';

const configurationExists = [
  ChannelTypeInternal.Switch,
  ChannelTypeInternal.Blind,
  ChannelTypeInternal.Gate,
  ChannelTypeInternal.Light,
  ChannelTypeInternal.Meter,
  (isDev || isLocalhost) && ChannelTypeInternal.Optimizer,
];

const ConfigurationForm: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation('configuration');
  const { t: tc } = useTranslation('common');
  const { t: td } = useTranslation('channel-details');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { channelType, channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();
  const { permissions } = usePermissionsContext();
  const { channel, deviceList } = useDevicesAndChannels({ channelId });
  const [infoPage, setInfoPage] = useState<InfoPageType>(InfoPageType.UNDEFINED);
  const { formMethods, onSubmit, submitActive, hiddenValue, handleChangeVisibility } = useConfigurationForm({
    channel,
  });
  const { shareChannel } = useShareRedirect();
  const { icon_name } = formMethods.watch();

  useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, []);

  const configurationVisible = useMemo(() => {
    return configurationExists.includes(channelType) && permissions[UserPermissionType.ChannelConfiguration];
  }, [configurationExists, channelType, permissions]);

  const tabs = useMemo(
    () => [{ label: t('general') }, ...(configurationVisible ? [{ label: t('configuration') }] : [])],
    [configurationVisible, t],
  );

  const goToDevice = () => history.push(ROUTES.DeviceSettings(channel?.deviceId));

  const handleTabChange = (tabIndex: number) => {
    history.replace(
      pathnameWithParameters(ROUTES.ChannelConfiguration(channelType, channelId), search, {
        key: 'tab',
        value: tabIndex,
      }),
    );
    setActiveTabIndex(tabIndex);
  };

  const openInfoPage = (type: InfoPageType) => setInfoPage(type);

  const renderConfigurationForm = () => {
    switch (channelType) {
      case ChannelTypeInternal.Blind:
        return <CoverConfiguration openInfoPage={openInfoPage} />;
      case ChannelTypeInternal.Gate:
        return <GateConfiguration openInfoPage={openInfoPage} />;
      case ChannelTypeInternal.Switch:
        return <SwitchConfiguration openInfoPage={openInfoPage} />;
      case ChannelTypeInternal.Light:
        return <LightConfiguration openInfoPage={openInfoPage} />;
      case ChannelTypeInternal.Meter:
        return <MeasurementsConfiguration meterChannelId={channelId} />;
      case ChannelTypeInternal.Optimizer:
        return <OptimizerConfiguration />;
      default:
        return null;
    }
  };

  const isDeviceAvailable = useMemo(() => {
    if (!channel) return false;
    return (
      deviceList.map((device) => device.id).includes(channel?.deviceId) &&
      !new URLSearchParams(window.location.search).get('redirect') &&
      permissions[UserPermissionType.DeviceList]
    );
  }, [deviceList, channel, permissions]);

  const iconList = useMemo(() => {
    if (channelType === ChannelTypeInternal.Gate)
      return availableIcons(channelType, (channel?.data as ChannelGateInterface)?.gateKind);
    return availableIcons(channelType);
  }, [channelType, (channel?.data as ChannelGateInterface)?.gateKind]);

  const handleChangeIcon = (value: string) => formMethods.setValue('icon_name', value);

  return (
    <>
      {infoPage === InfoPageType.UNDEFINED ? (
        <Page
          isStickyHeader
          className="channel_configuration"
          header={
            <>
              <NavHeader>
                <PopUpNav
                  links={
                    permissions[UserPermissionType.ChannelShare]
                      ? [
                          {
                            onClick: () => shareChannel(channel),
                            icon: <IconShare />,
                            label: td('shareChannel'),
                          },
                        ]
                      : []
                  }
                />
              </NavHeader>
              <Header title={t('channelSettings')} />
              <Tabs
                tabList={tabs}
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={handleTabChange}
                tabsType="pills"
                isDivider={false}
              />
            </>
          }
        >
          <div className="channel_configuration--content max-width-desktop">
            {activeTabIndex === 0 ? (
              <>
                {isDeviceAvailable ? <ArrowButton title={t('deviceSettings')} onClick={goToDevice} /> : null}
                <FormProvider {...formMethods}>
                  <form onSubmit={onSubmit}>
                    <hr className={c({ 'm-t-0': !isDeviceAvailable })} />
                    <ConfigurationName label={t('configurationNameLabel')} placeholder={t('configurationName')} />
                    <EditIcon
                      currentIcon={icon_name}
                      iconList={iconList}
                      handleChangeIcon={handleChangeIcon}
                      header={t('channelIcon')}
                    />
                    <hr />
                    <Info title={t('visibility')} onClick={() => openInfoPage(InfoPageType.VISIBILITY)} />
                    <div className="row_container">
                      <p>{t('hideNotUsed')}</p>
                      <Switch checked={hiddenValue} onChange={handleChangeVisibility} />
                    </div>

                    <SubmitButton disabled={!submitActive}>{tc('buttons.save')}</SubmitButton>
                  </form>
                </FormProvider>
              </>
            ) : (
              renderConfigurationForm()
            )}
          </div>
        </Page>
      ) : (
        <InfoPage infoPage={infoPage} changePage={setInfoPage} />
      )}
    </>
  );
};

export default ConfigurationForm;
