import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Header, NavHeader, Page, Tabs } from '../../components';
import { ChannelTypeInternal, VersionedFeatureType } from '../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../hooks';
import useVersionMap from '../../hooks/api/version-map';
import { useRouter } from '../../hooks/router/use-router';
import { ROUTES } from '../../routes';
import { pathnameWithParameters } from '../../utils/location';
import ConfigurationDetails from '../configuration/configuration-details';
import DeviceBindings from './device-bindings';
import DeviceChannels from './device-channels';
import DeviceInfo from './device-info';
import DeviceInputs from './device-inputs';
import './index.scss';

const DeviceSettings: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation('channel-list');
  const { t: ts } = useTranslation('device-settings');
  const { isBackAvailable, handleReplaceRedirect } = useRouter();
  const { checkSupport } = useVersionMap();
  const { deviceId } = useParams<{ deviceId: string; channelId: string }>();
  const { device, channelList } = useDevicesAndChannels({ deviceId });
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, []);

  const inputs = useMemo(() => {
    if (deviceId) {
      const channels = channelList.filter(
        (x) => x.deviceId === deviceId && x.data.type === ChannelTypeInternal.GenericInput,
      );

      return channels;
    }

    return [];
  }, [deviceId, channelList]);

  const bindingsEnabled = useMemo(
    () =>
      checkSupport({
        feature: VersionedFeatureType.DeviceBindingsV2,
        supportKey: 'Core',
        currentVersion: device?.payload.currentFirmwareVersion,
      }),
    [device?.payload.currentFirmwareVersion, inputs.length, checkSupport],
  );

  const tabs = [
    { label: t('configuration') },
    { label: t('channels') },
    ...(inputs.length ? [{ label: t('inputs') }] : []),
    ...(inputs.length && bindingsEnabled ? [{ label: ts('bindings.bindings') }] : []),
  ];

  const handleTabChange = (tabIndex: number) => {
    history.replace(pathnameWithParameters(ROUTES.DeviceSettings(deviceId), search, { key: 'tab', value: tabIndex }));
    setActiveTabIndex(tabIndex);
  };

  const renderCurrentForm = () => {
    switch (activeTabIndex) {
      case 1: {
        return (
          <>
            <Header subheader title={t('channels')} />
            <DeviceChannels />
          </>
        );
      }
      case 2: {
        return <DeviceInputs inputs={inputs} bindingsEnabled={bindingsEnabled} />;
      }
      case 3: {
        return <DeviceBindings />;
      }
      default: {
        return <ConfigurationDetails />;
      }
    }
  };

  if (!device) return null;

  return (
    <Page
      isStickyHeader
      className="driver-settings__page"
      header={
        <>
          <NavHeader {...(!isBackAvailable ? { onClick: () => handleReplaceRedirect(ROUTES.Root()) } : {})} />
          <Header>
            <div className="driver-settings__header">
              <DeviceInfo device={device} />
            </div>
          </Header>
          <Tabs
            tabList={tabs}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={handleTabChange}
            tabsType="pills"
            isDivider
          />
        </>
      }
    >
      <div className="p-b-32">{renderCurrentForm()}</div>
    </Page>
  );
};

export default DeviceSettings;
