import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  CustomBackdrop,
  EmptyStateBox,
  IconWarning,
  SubmitButton,
} from '../../../components';
import { InputConfiguration } from './components/input-configuration';
import { useDeviceBindings } from './hooks/use-device-bindings';
import { useSubmitBindings } from './hooks/use-submit-bindings';
import './index.scss';
import { DeviceBindingsForm } from './types';

const DeviceBindings: React.FC = () => {
  const { t } = useTranslation('device-settings');
  const { loading, inputs, form, channels } = useDeviceBindings();
  const { handleSubmitBindings } = useSubmitBindings();

  const handleSubmit = (values: DeviceBindingsForm) => {
    handleSubmitBindings(values);
  };

  return (
    <>
      {inputs.length ? (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            {inputs.map((input, index) => (
              <Accordion key={input.id} kind="custom" title={`${t('input')} ${input.alias}`}>
                <InputConfiguration channels={channels} index={index} />
              </Accordion>
            ))}
            <SubmitButton />
          </form>
        </FormProvider>
      ) : (
        <>{!loading && <EmptyStateBox content={t('bindings.noGenericInputs')} icon={<IconWarning />} />}</>
      )}
      <CustomBackdrop loading={loading} />
    </>
  );
};

export default DeviceBindings;
