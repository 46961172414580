import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { IconChannelsOrder, IconDelete, IconShare } from '../../../components/popup-nav/icons';
import {
  DashboardItemType,
  RemoveGroupMutation,
  RemoveGroupMutationVariables,
  UpdateGroupMutation,
  UpdateGroupMutationVariables,
  UserGroup,
  UserPermissionType,
} from '../../../data-access/gql-types/graphql';
import { UPDATE_GROUP } from '../../../data-access/mutations/groups';
import { REMOVE_GROUP } from '../../../data-access/mutations/groups';
import { useApi, useBackdropContext, useInstallation } from '../../../hooks';
import { useGroupErrors } from '../../../hooks/backend-errors/use-group-errors';
import { useRefetchData } from '../../../hooks/refresh-data';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { ROUTES } from '../../../routes';
import * as storage from '../../../utils/storage/lavva';
import { toastError, toastSuccess } from '../../../utils/toast';
import { useShareRedirect } from '../../installation-share/hooks/use-share-redirect';
import { useFormChannelsContext } from '../context';

export const useEditGroup = () => {
  const { t } = useTranslation('groups');
  const { t: tc } = useTranslation('common');
  const { groupId } = useParams<{ groupId: string }>();
  const history = useHistory();
  const { pathname } = useLocation();
  const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const { groups } = useApi();
  const [userGroup, setGroup] = useState<UserGroup>();
  const { isPristine, prepareGroupItems } = useFormChannelsContext();
  const [defaultChannels, setDefaultChannels] = useState<string[]>([]);
  const [defaultActions, setDefaultActions] = useState<string[]>([]);
  const { handleErrors } = useGroupErrors();
  const { selectedInstallationId } = useInstallation();
  const { shareGroup } = useShareRedirect();
  const { refetchDashboard } = useRefetchData();
  const { permissions } = usePermissionsContext();
  const [updateGroup, { loading: editGroupNameLoading }] = useMutation<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >(UPDATE_GROUP);
  const form = useForm({
    defaultValues: {
      name: '',
      iconName: '',
    },
  });
  const [removeGroup] = useMutation<RemoveGroupMutation, RemoveGroupMutationVariables>(REMOVE_GROUP);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const canShare = useMemo(
    () => permissions[UserPermissionType.GroupShare] && userGroup?.group.isPublicGroup,
    [userGroup?.group.isPublicGroup, permissions],
  );

  useEffect(() => {
    if (groupId) setGroup(groups?.find((group) => group.id === groupId));
  }, [groups, groupId]);

  useEffect(() => {
    if (userGroup && !defaultChannels.length) {
      setDefaultChannels(
        userGroup.group.groupItems
          ?.filter((item) => item.id && item.resourceType === DashboardItemType.Channel)
          .map((item) => item.id) || [],
      );
    }

    if (userGroup && !defaultActions?.length) {
      setDefaultActions(
        userGroup.group.groupItems
          ?.filter((item) => item.id && item.resourceType === DashboardItemType.Action)
          .map((item) => item.id) || [],
      );
    }

    if (userGroup) {
      form.setValue('name', userGroup.group.name);
      form.setValue('iconName', userGroup.group.iconName);
    }
  }, [userGroup]);

  const openRemoveDialog = useCallback(() => setShowDeleteDialog(true), []);
  const closeRemoveDialog = useCallback(() => setShowDeleteDialog(false), []);
  const handleAttemptToSave = useCallback(() => setShowSaveDialog(true), []);

  const handleChangeIcon = (value: string) => form.setValue('iconName', value);

  const handleRemoveGroup = useCallback(() => {
    if (userGroup) {
      turnOnBackdrop();
      closeRemoveDialog();
      removeGroup({
        variables: {
          input: {
            installationId: selectedInstallationId,
            groupId: userGroup?.id,
          },
        },
        onCompleted: (data) => {
          turnOffBackdrop();

          if (data.removeGroup.result?.ok) {
            refetchDashboard();
            toastSuccess({ content: t('groupDeleted') });
            history.goBack();
          } else {
            handleErrors(data.removeGroup.errors || []);
          }
        },
        onError: () => {
          turnOffBackdrop();
          toastError({ content: `${t('removeError.default')}` });
        },
      });
    }
  }, [userGroup, selectedInstallationId]);

  const onCancel = useCallback(() => {
    setShowSaveDialog(false);
    setShowDeleteDialog(false);
  }, [setShowSaveDialog]);

  const handleRedirectionToGroupSortChannels = useCallback(() => {
    const isListView = storage.getItem('isListView');
    storage.setItem('lastActiveGroupId', String(userGroup?.id));

    history.push(ROUTES.GroupSortChannels(isListView));
  }, [userGroup]);

  const confirmSubmit = () => {
    const { name, iconName } = form.getValues();
    const groupItems = prepareGroupItems();

    if (userGroup) {
      turnOnBackdrop();
      updateGroup({
        variables: {
          input: {
            installationId: selectedInstallationId,
            groupId: userGroup.id,
            name,
            groupItems,
            iconName,
          },
        },
        onCompleted: (data) => {
          turnOffBackdrop();
          if (data.updateGroup.result?.ok) {
            toastSuccess({ content: t('groupEdited') });
            refetchDashboard();
            setShowSaveDialog(false);
            history.goBack();
          } else {
            handleErrors(data.updateGroup.errors || []);
          }
        },
        onError: () => turnOffBackdrop(),
      });
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  };

  const onSubmit = form.handleSubmit(() => handleAttemptToSave());

  const canRemoveGroup = useMemo(() => {
    if (!groupId) return false;
    return userGroup?.group.isPublicGroup
      ? permissions[UserPermissionType.PublicGroupRemove]
      : permissions[UserPermissionType.GroupRemove];
  }, [permissions, groupId, userGroup]);

  const popupNavLinks = useMemo(() => {
    return [
      { label: t('channelsOrder'), onClick: handleRedirectionToGroupSortChannels, icon: <IconChannelsOrder /> },
      ...(canRemoveGroup
        ? [{ label: t('remove'), onClick: openRemoveDialog, hasLineBelow: canShare, icon: <IconDelete /> }]
        : []),
      ...(canShare
        ? [
            {
              label: t('shareGroup'),
              onClick: () => shareGroup(userGroup),
              icon: <IconShare />,
            },
          ]
        : []),
    ];
  }, [groupId, canShare, canRemoveGroup, handleRedirectionToGroupSortChannels, openRemoveDialog, shareGroup]);

  const isfavouriteForm = useMemo(() => pathname === ROUTES.FavouritesForm(groupId), [pathname, groupId]);

  return {
    showDeleteDialog,
    showSaveDialog,
    userGroup,
    form,
    editGroupNameLoading,
    isPristine,
    defaultChannels,
    defaultActions,
    popupNavLinks,
    isfavouriteForm,
    setShowDeleteDialog,
    setShowSaveDialog,
    handleChangeIcon,
    onSubmit,
    handleRemoveGroup,
    handleAttemptToSave,
    confirmSubmit,
    closeRemoveDialog,
    onCancel,
  };
};
