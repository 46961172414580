import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useActionBatch } from '../../api/modules/action/action.hooks';
import {
  BatchChannel,
  GateDirectionBatchChannel,
  GatePositionBatchChannel,
  TypeOfActionType,
} from '../../api/modules/action/action.types';
import {
  useGateSetDirection,
  useGateSetPosition,
  useGateStepByStep,
  useGateTilt,
  useSetElectricStrikeState,
} from '../../api/modules/gate/gate.hooks';
import {
  GateModeInternal,
  SetQuickControlMutation,
  SetQuickControlMutationVariables,
} from '../../data-access/gql-types/graphql';
import { SET_QUICK_CONTROL } from '../../data-access/mutations/channels';
import { ChannelInterface } from '../../types';
import { ChannelGateInterface, ChannelGateStateInterface } from '../../types/channel/gate';
import { toastError, toastInfo, toastSuccess } from '../../utils/toast';
import { useChannelErrors } from '../backend-errors/use-channel-errors';
import { useChannelsState } from '../channels-state';
import { useDevicesAndChannels } from '../devices-and-channels';

export enum GateKindParam {
  ChannelsDetails,
  Action,
}

interface ChannelDetailsParams {
  kind: GateKindParam.ChannelsDetails;
  channel: ChannelInterface;
}

interface ActionParams {
  kind: GateKindParam.Action;
  channels: ChannelInterface[];
}

type Params = ChannelDetailsParams | ActionParams;

interface UseGateActionInterface {
  handleAction: (value: number | string) => void;
  handleVentilation: () => void;
  handleStepByStep: () => void;
  handleSetElectricStrikeState: () => void;
  handleQuickControl: (value: boolean) => void;
}

export enum Direction {
  unknown = 0,
  stop = 1,
  open = 2,
  close = 3,
}

export const useGate = (params: Params): UseGateActionInterface => {
  const { t: td } = useTranslation('channel-details');
  const { t: ta } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { handleErrors } = useChannelErrors();
  const { setChannelList } = useDevicesAndChannels();
  const setGateDirection = useGateSetDirection();
  const setGatePosition = useGateSetPosition();
  const setGateTilt = useGateTilt();
  const actionBatch = useActionBatch();
  const stepByStepMutate = useGateStepByStep();
  const electricStrikeStateMutation = useSetElectricStrikeState();
  const { channelState } = useChannelsState();
  const [setQuickControl] = useMutation<SetQuickControlMutation, SetQuickControlMutationVariables>(SET_QUICK_CONTROL);

  const handleAction = (value: string | number) => {
    const { kind } = params;

    if (kind === GateKindParam.ChannelsDetails) {
      const { channel } = params;

      if (typeof value === 'string') {
        setGateDirection.mutate({
          channelId: channel.id,
          deviceId: channel.deviceId,
          direction: Direction[value],
        });
      } else {
        setGatePosition.mutate({ channelId: channel.id, deviceId: channel.deviceId, position: value });
      }
    } else {
      const { channels } = params;

      if (!channels.length) {
        toastInfo({ content: ta('trigger.validationErrors.deviceNotFound') });
        return;
      }

      if (typeof value === 'string') {
        actionBatch.mutate({
          controls: channels
            .filter((x) => (x.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp)
            .map((channel) => ({
              channelId: channel.id,
              deviceId: channel.deviceId,
              direction: Direction[value],
              $type: TypeOfActionType.BatchGateSetDirectionRequest,
            })) as GateDirectionBatchChannel[],
        });
      } else {
        actionBatch.mutate({
          controls: channels
            .filter((x) => (x.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp)
            .map((channel) => ({
              channelId: channel.id,
              deviceId: channel.deviceId,
              position: value,
              $type: TypeOfActionType.BatchGateSetPositionRequest,
            })) as GatePositionBatchChannel[],
        });
      }
    }
  };

  const handleVentilation = () => {
    const { kind } = params;

    if (kind === GateKindParam.ChannelsDetails) {
      const { channel } = params;

      setGateTilt.mutate({ channelId: channel.id, deviceId: channel.deviceId });
    } else {
      const { channels } = params;
      actionBatch.mutate({
        controls: channels.map((channel) => ({
          channelId: channel.id,
          deviceId: channel.deviceId,
          $type: TypeOfActionType.BatchGateTiltRequest,
        })) as BatchChannel[],
      });
    }
  };

  const handleStepByStep = () => {
    const { kind } = params;

    if (kind === GateKindParam.ChannelsDetails) {
      const { channel } = params;

      stepByStepMutate.mutate({ channelId: channel.id, deviceId: channel.deviceId });
    } else {
      const { channels } = params;
      actionBatch.mutate({
        controls: channels.map((channel) => ({
          channelId: channel.id,
          deviceId: channel.deviceId,
          $type: TypeOfActionType.BatchGateStepByStepRequest,
        })) as BatchChannel[],
      });
    }
  };

  const handleSetElectricStrikeState = () => {
    const { kind } = params;

    if (kind === GateKindParam.ChannelsDetails) {
      const { channel } = params;

      electricStrikeStateMutation.mutate({
        channelId: channel.id,
        deviceId: channel.deviceId,
        value: !(channelState[channel.id] as ChannelGateStateInterface).electricStrikeStatus,
      });
    }
  };

  const handleQuickControl = (value: boolean) => {
    const { kind } = params;

    if (kind === GateKindParam.ChannelsDetails) {
      const { channel } = params;

      setQuickControl({
        variables: {
          input: {
            channelId: channel.id,
            value,
          },
        },
        onCompleted: (data) => {
          if (data.setQuickControl.result?.ok) {
            setChannelList((prev) => {
              const temp = [...prev];
              const index = temp.findIndex((x) => x.id === channel.id);

              if (index !== -1) {
                (temp[index].data as ChannelGateInterface).isQuickControl = value;
              }

              return [...temp];
            });

            toastSuccess({ content: td('quickControlSuccess') });
          } else {
            handleErrors(data.setQuickControl.errors || []);
          }
        },
        onError: () => {
          toastError({ content: tc('errors.somethingWentWrong') });
        },
      });
    }
  };

  return {
    handleAction,
    handleVentilation,
    handleStepByStep,
    handleSetElectricStrikeState,
    handleQuickControl,
  };
};
