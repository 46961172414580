import { gql } from '@apollo/client';

export const ON_SWITCH_STATE_CHANGE = gql`
  subscription onSwitchStateChange($channelId: UUID!) {
    onSwitchStateChange(channelId: $channelId) {
      channelId
      state
    }
  }
`;

export const ON_BLIND_POSITION_CHANGE = gql`
  subscription onBlindPositionChange($channelId: UUID!) {
    onBlindPositionChange(channelId: $channelId) {
      channelId
      position
      direction
      predictedTimeInMs
      targetPosition
      slatsPredictedTimeInMs
    }
  }
`;

export const ON_GATE_POSITION_CHANGE = gql`
  subscription onGatePositionChange($channelId: UUID!) {
    onGatePositionChange(channelId: $channelId) {
      deviceId
      installationId
      channelId
      position
      direction
      predictedTimeInMs
      targetPosition
    }
  }
`;

export const ON_LIGHT_TOGGLE_STATE_CHANGE = gql`
  subscription onLightToggleStateChange($channelId: UUID!) {
    onLightToggleStateChange(channelId: $channelId) {
      channelId
      toggleValue
    }
  }
`;

export const ON_LIGHT_BRIGHTNESS_CHANGE = gql`
  subscription onLightBrightnessChange($channelId: UUID!) {
    onLightBrightnessChange(channelId: $channelId) {
      channelId
      brightness
    }
  }
`;

export const ON_LIGHT_TEMPERATURE_CHANGE = gql`
  subscription onLightTemperatureChange($channelId: UUID!) {
    onLightTemperatureChange(channelId: $channelId) {
      channelId
      temperature
    }
  }
`;

export const ON_LIGHT_COLOR_CHANGE = gql`
  subscription onLightColorChange($channelId: UUID!) {
    onLightColorChange(channelId: $channelId) {
      channelId
      color {
        r
        g
        b
      }
    }
  }
`;

export const ON_REQUEST_ACK_RECEIVED = gql`
  subscription onRequestAckReceived($channelOrDeviceId: UUID!) {
    onRequestAckReceived(channelOrDeviceId: $channelOrDeviceId) {
      deviceId
      status
    }
  }
`;

export const ON_DEVICE_CONNECTION_STATE_CHANGE = gql`
  subscription OnDeviceConnectionStateChange($installationId: UUID!) {
    onDeviceConnectionStateChange(installationId: $installationId) {
      deviceId
      deviceConnectionState
    }
  }
`;

export const ON_BLIND_CALIBRATE_STATUS_CHANGE = gql`
  subscription OnBlindCalibrateStatusChange($channelId: UUID!) {
    onBlindCalibrateStatusChange(channelId: $channelId) {
      channelId
      progress
      status
      openingMilliseconds
      closingMilliseconds
      error
    }
  }
`;

export const ON_DEVICE_FIRMWARE_UPDATED = gql`
  subscription OnDeviceFirmwareUpdated($deviceId: UUID!) {
    onDeviceFirmwareUpdated(deviceId: $deviceId) {
      currentFirmwareVersion
      deviceId
    }
  }
`;

export const ON_RECOVERY_DEVICE_STATUS_RECEIVED_PAYLOAD = gql`
  subscription OnRecoveryDeviceStatusReceivedPayload($deviceId: UUID!) {
    onRecoveryDeviceStatusRecievedPayload(deviceId: $deviceId) {
      deviceId
      recoveryDeviceStatus
      progress
    }
  }
`;

export const ON_DEVICE_WIFI_NETWORKS_RECEIVED = gql`
  subscription OnDeviceWifiNetworksReceived($deviceId: UUID!) {
    onDeviceWifiNetworksReceived(deviceId: $deviceId) {
      wifiNetworks {
        remembered
        rssi
        ssid
      }
    }
  }
`;

export const ON_DEVICE_CONNECTED_TO_SSID_UPDATED = gql`
  subscription OnDeviceConnectedToSsidUpdated($deviceId: UUID!) {
    onDeviceConnectedToSsidUpdated(deviceId: $deviceId) {
      connectedToSsid
      deviceId
    }
  }
`;

export const ON_OPTIMIZER_CONFIGURATION_CHANGED = gql`
  subscription OnOptimizerConfigurationUpdatedPayload($channelId: UUID!) {
    onOptimizerConfigurationUpdatedPayload(channelId: $channelId) {
      channelId
    }
  }
`;

export const ON_GATE_CALIBRATE_STATUS_CHANGE = gql`
  subscription OnGateCalibrateStatusChange($channelId: UUID!) {
    onGateCalibrateStatusChange(channelId: $channelId) {
      channelId
      progress
      status
      openingMilliseconds
      closingMilliseconds
      error
    }
  }
`;

export const ON_GENERIC_INPUT_STATE_CHANGE = gql`
  subscription OnGenericInputStateChange($channelId: UUID!) {
    onGenericInputStateChange(channelId: $channelId) {
      channelId
      bistableMode
      monostableMode
      genericInputType
    }
  }
`;

export const ON_GENERIC_INPUT_STATUS_CHANGED = gql`
  subscription OnGenericInputStatusChanged($deviceId: UUID!) {
    onGenericInputStatusChanged(deviceId: $deviceId) {
      value
      channelId
      installationId
      deviceId
    }
  }
`;

export const ON_SUPPLY_RETURN_BEHAVIOR_CHANGE = gql`
  subscription OnSupplyReturnBehaviorChange($channelId: UUID!) {
    onSupplyReturnBehaviorChange(channelId: $channelId) {
      channelId
      supplyReturnBehavior
    }
  }
`;

export const ON_GATE_CONFIGURATION_CHANGE = gql`
  subscription OnGateConfigurationChange($channelId: UUID!) {
    onGateConfigurationChange(channelId: $channelId) {
      channelId
      supportedGateFeatures
    }
  }
`;

export const ON_OPTIMIZER_CONFIGURATION_TAINTED_PAYLOAD = gql`
  subscription OnOptimizerConfigurationTaintedPayload($channelId: UUID!) {
    onOptimizerConfigurationTaintedPayload(channelId: $channelId) {
      channelId
      deviceId
      installationId
      failures
    }
  }
`;

export const ON_DEVICE_ERROR = gql`
  subscription OnDeviceError($installationId: UUID!) {
    onDeviceError(installationId: $installationId) {
      code
      description
      installationId
      deviceId
    }
  }
`;

export const ON_PARTIAL_CONFIG_CHANGED = gql`
  subscription OnPartialConfigChanged($channelId: UUID!) {
    onPartialConfigChanged(channelId: $channelId) {
      stringProperties {
        property
        value
      }
      numericalProperties {
        property
        value
      }
      channelId
      deviceId
      installationId
    }
  }
`;

export const ON_GATE_ELECTRIC_STRIKE_STATUS_CHANGED = gql`
  subscription OnGateElectricStrikeStatusChanged($channelId: UUID!) {
    onGateElectricStrikeStatusChanged(channelId: $channelId) {
      channelId
      value
    }
  }
`;