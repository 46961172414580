import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { aggregatedParameters } from '../../../modules/channel-details/measurement/utils';
import { ChartDataItem } from '../../../modules/channel-details/meter/analysis';
import { predictionParameters } from '../../../modules/channel-details/meter/analysis/utils/helpers';
import { MeasurementRange } from '../../../types';
import { IconSchedule } from '../../icons';
import './index.scss';

interface PropsInterface {
  summaryData: ChartDataItem[];
  activeMeasurementRange: MeasurementRange;
}

export const Summary: React.FC<PropsInterface> = ({ summaryData, activeMeasurementRange }) => {
  const { t } = useTranslation('channel-details');
  const [energyStats, setEnergyStats] = useState({});

  useEffect(() => {
    const stats = {};

    summaryData.forEach((entry) => {
      entry.values.forEach((val) => {
        const type = val.type;
        if (predictionParameters.includes(type)) return;

        if (!stats[type]) {
          stats[type] = { sum: 0, max: null, count: 0, color: val.color };
        }

        if (!val.value) return;

        stats[type].sum += val.value;
        stats[type].max = Math.max(stats[type].max, val.value);
        stats[type].count++;
      });
    });

    Object.keys(stats).forEach((type) => {
      const average = stats[type].count ? stats[type].sum / stats[type].count : 0;
      stats[type].average = average;
    });

    setEnergyStats(stats);
  }, [summaryData]);

  const summarySectionName = useMemo(() => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours:
        return t('summary.daily');
      case MeasurementRange.WeekInDays:
        return t('summary.weekly');
      case MeasurementRange.MonthInDays:
        return t('summary.monthly');
      case MeasurementRange.YearInMonths:
        return t('summary.yearly');
      case MeasurementRange.TenYearsInYears:
        return t('summary.tenYears');
      default:
        return '';
    }
  }, [activeMeasurementRange]);

  return (
    <div className="summary max-width-desktop">
      {Object.keys(energyStats).length ? (
        <div className="summary__caption">
          <IconSchedule />
          <span className="summary__caption-text"> {summarySectionName}</span>
        </div>
      ) : null}
      {Object.keys(energyStats).map((type) => (
        <div key={type} className="summary__section summary__section--border">
          {Object.keys(energyStats).length !== 1 ? (
            <div className="summary__section-label">
              {t(`energyMeasurements.types.summary.${type}`)}
              <div className="label-color" style={{ backgroundColor: energyStats[type].color }} />
            </div>
          ) : null}
          <div className="summary__section-row">
            <span>{t('summary.sum')}</span>
            <span className="summary__section-value">
              {`${energyStats[type].sum.toFixed(2)} ${aggregatedParameters[type].unit}`}
            </span>
          </div>
          <div className="summary__section-row">
            <span>{t('summary.average')}</span>
            <span className="summary__section-value">
              {`${energyStats[type].average.toFixed(2)} ${aggregatedParameters[type].unit}`}
            </span>
          </div>
          <div className="summary__section-row">
            <span>{t('summary.max')}</span>
            <span className="summary__section-value">
              {`${energyStats[type].max !== null ? energyStats[type].max.toFixed(2) : '-'} ${
                aggregatedParameters[type].unit
              }`}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
