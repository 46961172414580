import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import {
  ON_BLIND_CALIBRATE_STATUS_CHANGE,
  ON_BLIND_POSITION_CHANGE,
  ON_REQUEST_ACK_RECEIVED,
} from '../../../../data-access/subscriptions/lavva-devices';
import { useChannelsState, useInstallation } from '../../../../hooks';
import { useUpdateCommonLavvaState } from '../common-lavva-state';
import { ChannelSubscriptionParams } from '../types';
import { useLavvaDeviceBlindUpdate } from './update';

export const useBlindSubscriptions = ({ channelId }: ChannelSubscriptionParams): void => {
  const { updateLavvaBlindPosition, onBlindCalibrateStatusChange, clearAnimationIfExists } =
    useLavvaDeviceBlindUpdate();
  const { onRequestAckReceived } = useUpdateCommonLavvaState();
  const { channelState } = useChannelsState();
  const { skipLavvaFetch } = useInstallation();
  const { data: blindPosition } = useSubscription(ON_BLIND_POSITION_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: ackReceived } = useSubscription(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: channelId },
    skip: skipLavvaFetch,
  });
  const { data: blindCalibration } = useSubscription(ON_BLIND_CALIBRATE_STATUS_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (channelId && !channelState[channelId].deviceConnectionState) {
      clearAnimationIfExists();
    }
  }, [channelState[channelId || '']?.deviceConnectionState]);

  useEffect(() => {
    if (blindPosition) updateLavvaBlindPosition(blindPosition.onBlindPositionChange);
  }, [blindPosition]);

  useEffect(() => {
    if (ackReceived) onRequestAckReceived(ackReceived.onRequestAckReceived, clearAnimationIfExists);
  }, [ackReceived]);

  useEffect(() => {
    if (blindCalibration) onBlindCalibrateStatusChange(blindCalibration.onBlindCalibrateStatusChange);
  }, [blindCalibration]);
};
