import React from 'react';
import { GateKindParam } from '../../../../hooks/use-gate';
import { ChannelInterface } from '../../../../types';
import { GateActionControlBox } from './action';
import { GateChannelControlBox } from './channel';

interface CommonInterface {
  isListItem?: boolean;
}

interface ChannelDetailsParams extends CommonInterface {
  kind: GateKindParam.ChannelsDetails;
  channel: ChannelInterface;
}

interface ActionParams extends CommonInterface {
  kind: GateKindParam.Action;
  channels: ChannelInterface[];
}

type PropsInterface = ChannelDetailsParams | ActionParams;

export const GateControlBox: React.FC<PropsInterface> = (props) => {
  return props.kind === GateKindParam.ChannelsDetails ? (
    <GateChannelControlBox isListItem={props.isListItem} channel={props.channel} />
  ) : (
    <GateActionControlBox isListItem={props.isListItem} channels={props.channels} />
  );
};
