import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LavvaDetailsWrapper from '../../../components/details-wrapper';
import { ChannelTypeInternal, MeterTypeInternal } from '../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../hooks';
import { ChannelInterface } from '../../../types';
import { MeterAnalysis } from './analysis';
import { MeterCurrent } from './current';
import { MeterMeasurements } from './measurements/meter';

interface PropsInterface {
  channel: ChannelInterface;
}

const ChannelDetailsMeter: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { carouselDisabled } = useBackdropContext();

  const isStandalone = useMemo(() => {
    return channel.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  return (
    <LavvaDetailsWrapper
      preventSwipeEvent={carouselDisabled}
      tabs={[
        { label: t('currently') },
        { label: t('measurement') },
        ...(isStandalone ? [{ label: t('analysis') }] : []),
      ]}
      additionalTabs={[
        <MeterMeasurements key="2" channel={channel} />,
        ...(isStandalone ? [<MeterAnalysis key="3" channel={channel} />] : []),
      ]}
    >
      <MeterCurrent channel={channel} />
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsMeter;
