import { rgbToHex } from '@mui/material';
import {
  DeviceConnectionState,
  LightBrightnessChangePayload,
  LightChannelColorStateResponse,
  LightColorChangePayload,
  LightTemperatureChangePayload,
  LightToggleStateChangePayload,
} from '../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../hooks';
import { ChannelLightStateInterface } from '../../../../types';

export const useLavvaDeviceLightUpdate = () => {
  const { setChannelState } = useChannelsState();

  const updateLavvaLightState = ({ channelId, toggleValue }: LightToggleStateChangePayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        toggleValue,
      } as ChannelLightStateInterface;

      return newState;
    });
  };

  const updateLavvaLightBrightness = ({ channelId, brightness }: LightBrightnessChangePayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        brightness,
      } as ChannelLightStateInterface;

      return newState;
    });
  };

  const updateLavvaLightTemperature = ({ channelId, temperature }: LightTemperatureChangePayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        temperature,
      } as ChannelLightStateInterface;

      return newState;
    });
  };

  const updateLavvaLightColor = ({ channelId, color }: LightColorChangePayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        color: {
          ...color,
          hex: rgbToHex(`rgb(${color.r}, ${color.g}, ${color.b})`),
        } as LightChannelColorStateResponse,
      } as ChannelLightStateInterface;

      return newState;
    });
  };

  return {
    updateLavvaLightState,
    updateLavvaLightBrightness,
    updateLavvaLightTemperature,
    updateLavvaLightColor,
  };
};
