import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useActionBatch } from '../../api/modules/action/action.hooks';
import {
  ActionBatchBody,
  BatchChannel,
  CoverDirectionBatchChannel,
  CoverPositionBatchChannel,
  GateDirectionBatchChannel,
  GatePositionBatchChannel,
  LightBrightnessBatchChannel,
  LightRGBBatchChannel,
  SwitchLightOnOffBatchChannel,
  TypeOfActionType,
} from '../../api/modules/action/action.types';
import { ActivityType, ChannelTypeInternal, UserAction } from '../../data-access/gql-types/graphql';
import { DashboardUserActionInterface } from '../../types';
import { toastInfo } from '../../utils/toast';
import { Direction } from '../use-cover';

export const useAdvancedAction = (userAction: DashboardUserActionInterface | UserAction) => {
  const { t } = useTranslation('action');
  const actionBatch = useActionBatch();

  const handleAction = useCallback(() => {
    const allControls = userAction.action.channelActions
      .map((channelAction) => {
        switch (channelAction.activityType) {
          case ActivityType.On:
            return {
              deviceId: channelAction.channelId,
              channelId: channelAction.channelId,
              value: true,
              $type: TypeOfActionType.BatchSwitchToggleOnOffRequest,
            } as SwitchLightOnOffBatchChannel;
          case ActivityType.Off:
            return {
              deviceId: channelAction.channelId,
              channelId: channelAction.channelId,
              value: false,
              $type: TypeOfActionType.BatchSwitchToggleOnOffRequest,
            } as SwitchLightOnOffBatchChannel;
          case ActivityType.Open: {
            if (channelAction.channelType === ChannelTypeInternal.Blind) {
              return {
                deviceId: channelAction.channelId,
                channelId: channelAction.channelId,
                direction: Direction.open,
                $type: TypeOfActionType.BatchBlindSetDirectionRequest,
              } as CoverDirectionBatchChannel;
            }

            if (channelAction.channelType === ChannelTypeInternal.Gate) {
              return {
                deviceId: channelAction.channelId,
                channelId: channelAction.channelId,
                direction: Direction.open,
                $type: TypeOfActionType.BatchGateSetDirectionRequest,
              } as GateDirectionBatchChannel;
            }

            return;
          }
          case ActivityType.Close: {
            if (channelAction.channelType === ChannelTypeInternal.Blind) {
              return {
                deviceId: channelAction.channelId,
                channelId: channelAction.channelId,
                direction: Direction.close,
                $type: TypeOfActionType.BatchBlindSetDirectionRequest,
              } as CoverDirectionBatchChannel;
            }

            if (channelAction.channelType === ChannelTypeInternal.Gate) {
              return {
                deviceId: channelAction.channelId,
                channelId: channelAction.channelId,
                direction: Direction.close,
                $type: TypeOfActionType.BatchGateSetDirectionRequest,
              } as GateDirectionBatchChannel;
            }

            return;
          }
          case ActivityType.Stop: {
            if (channelAction.channelType === ChannelTypeInternal.Blind) {
              return {
                deviceId: channelAction.channelId,
                channelId: channelAction.channelId,
                direction: Direction.stop,
                $type: TypeOfActionType.BatchBlindSetDirectionRequest,
              } as CoverDirectionBatchChannel;
            }

            if (channelAction.channelType === ChannelTypeInternal.Gate) {
              return {
                deviceId: channelAction.channelId,
                channelId: channelAction.channelId,
                direction: Direction.stop,
                $type: TypeOfActionType.BatchGateSetDirectionRequest,
              } as GateDirectionBatchChannel;
            }

            return;
          }

          case ActivityType.SetPosition: {
            if (channelAction.channelType === ChannelTypeInternal.Blind) {
              return {
                deviceId: channelAction.channelId,
                channelId: channelAction.channelId,
                position: parseInt(channelAction.value),
                $type: TypeOfActionType.BatchBlindSetPositionRequest,
              } as CoverPositionBatchChannel;
            }

            if (channelAction.channelType === ChannelTypeInternal.Gate) {
              return {
                deviceId: channelAction.channelId,
                channelId: channelAction.channelId,
                position: parseInt(channelAction.value),
                $type: TypeOfActionType.BatchGateSetPositionRequest,
              } as GatePositionBatchChannel;
            }

            return;
          }

          case ActivityType.SetDim:
            return {
              deviceId: channelAction.channelId,
              channelId: channelAction.channelId,
              brightness: parseInt(channelAction.value),
              $type: TypeOfActionType.BatchLightSetBrightnessRequest,
            } as LightBrightnessBatchChannel;
          case ActivityType.SetLightColor: {
            const [R, G, B] = channelAction.value.split(',').map((x) => parseInt(x));

            return {
              deviceId: channelAction.channelId,
              channelId: channelAction.channelId,
              r: R,
              g: G,
              b: B,
              $type: TypeOfActionType.BatchLightSetRgbRequest,
            } as LightRGBBatchChannel;
          }
          case ActivityType.Tilt:
            return {
              deviceId: channelAction.channelId,
              channelId: channelAction.channelId,
              $type: TypeOfActionType.BatchGateTiltRequest,
            } as BatchChannel;
        }
      })
      .filter((x) => x?.channelId);

    if (allControls.length) {
      actionBatch.mutate({
        controls: allControls,
      } as ActionBatchBody);
    }
  }, [userAction]);

  const setActionOn = useCallback(() => {
    if (!userAction?.action.channelActions.length) {
      toastInfo({ content: t('trigger.validationErrors.deviceNotFound') });
      return;
    }

    handleAction();
  }, [handleAction]);

  return { setActionOn };
};
