import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChannelBox } from '../../../../components';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../../hooks';
import { ChannelLightInterface } from '../../../../types';
import { InfoPageType } from '../../types';
import Info from '../components/info';
import { InputsType } from '../components/inputs-type';
import ReturnBehaviorSelect from '../components/return-behavior-select';
import SwapIn from '../components/swap-in';
import MeasurementsConfiguration from './measurements';

interface ComponentProps {
  openInfoPage: (type: InfoPageType) => void;
}

const LightConfiguration: React.FC<ComponentProps> = ({ openInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();
  const { channel } = useDevicesAndChannels({ channelId });

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => openInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem />
      <hr />

      <Info title={t('inService')} onClick={() => openInfoPage(InfoPageType.INSERVICE)} />
      <SwapIn channel={channel} />
      <hr />
      <InputsType openInfoPage={openInfoPage} isUnderlineDown />
      <ReturnBehaviorSelect channel={channel} />
      {(channel.data as ChannelLightInterface).measuredBy?.meterChannelId && (
        <>
          <hr />
          <MeasurementsConfiguration
            meterChannelId={(channel.data as ChannelLightInterface).measuredBy?.meterChannelId}
          />
        </>
      )}
    </>
  );
};

export default LightConfiguration;
