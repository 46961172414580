import { Dictionary } from 'lodash';
import {
  AggregateVectorParameterTypeInternal,
  VectorMeasurementResponseItem,
} from '../../../../../data-access/gql-types/graphql';
import { getSelectedTheme } from '../../../../../utils/theme';

const isDark = getSelectedTheme() === 'dark';

export const parameterColor = {
  [AggregateVectorParameterTypeInternal.ForwardActiveEnergy]: '#FF4016',
  [AggregateVectorParameterTypeInternal.ReverseActiveEnergy]: '#00B191',
  [AggregateVectorParameterTypeInternal.ForwardReactiveEnergy]: '#9c27b0',
  [AggregateVectorParameterTypeInternal.ReverseReactiveEnergy]: '#FCAF00',
  [AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy]: '#2196f3',
  [AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy]: isDark ? '#EDECE8' : '#242423',
  [AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy]: isDark ? 'F7F3E4' : '#452929',
};

export const sortedChartsParameters = [
  AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
  AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
  AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
];

export const predictionParameters = [
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
];

export const noPredictionValues = [
  AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
  AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
  AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
];

export const getWeekDate = (startDate: Date, index: number) => {
  const startOfWeek = new Date(startDate);

  const currentDay = new Date(startOfWeek);
  currentDay.setDate(startOfWeek.getDate() + index);

  return currentDay;
};

export const getMonday = (date = new Date()) => {
  const dayOfWeek = date.getDay();
  const diffToMonday = (dayOfWeek + 6) % 7;
  const lastMonday = new Date(date);
  lastMonday.setDate(date.getDate() - diffToMonday);
  return lastMonday;
};

const orderParameterList = [10, 11, 12, 13, 14];

export const sortedParameters = (list: VectorMeasurementResponseItem[]) => {
  return [...list].sort((a, b) => {
    const aIncluded = orderParameterList.includes(a.type);
    const bIncluded = orderParameterList.includes(b.type);

    if (aIncluded && !bIncluded) return -1;
    if (!aIncluded && bIncluded) return 1;
    return 0;
  });
};

export const sortedSummary = (phaseMeasurementsGroupedByType: Dictionary<VectorMeasurementResponseItem[]>) => {
  return Object.entries({ ...phaseMeasurementsGroupedByType }).sort((a, b) => {
    const aIsPriority = orderParameterList.includes(parseInt(a[0]));
    const bIsPriority = orderParameterList.includes(parseInt(b[0]));

    if (aIsPriority && !bIsPriority) return -1;
    if (!aIsPriority && bIsPriority) return 1;
    return 0;
  });
};
