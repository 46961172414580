import { Dispatch, SetStateAction } from 'react';
import { SelectOptionInterface } from '../../../../../components';
import { CheckboxesOptions } from '../../../../../components/checkbox-select';
import {
  AggregateVectorParameterTypeInternal,
  GetAggregateVectorMeasurementResponse,
  Maybe,
  MeasuredBy,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelInterface, MeasurementRange } from '../../../../../types';
import { ChartDataItem } from '../index';

export interface AnalysisHook {
  data: Maybe<GetAggregateVectorMeasurementResponse> | undefined;
  isLoading: boolean;
  mappedChartData: ChartDataItem[];
  onClickActiveMeasurementRange: (value: MeasurementRange) => void;
  setAggregatedParameters: Dispatch<SetStateAction<CheckboxesOptions<AggregateVectorParameterTypeInternal>[]>>;
  aggregatedParameters: CheckboxesOptions<AggregateVectorParameterTypeInternal>[];
  activeMeasurementRange: MeasurementRange;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  timeRanges: SelectOptionInterface<MeasurementRange>[];
}

export interface AnalysisHookParams {
  channel: ChannelInterface;
  measurementChannel?: MeasuredBy;
}

export enum AvailableShortFormatEnum {
  HOURLY = 'HH:mm',
  WEEKLY = 'EEEEEE',
  MONTHLY = 'dd.LL',
  YEARLY = 'LL',
  TEN_YEARS = 'yyyy',
}

export enum AvailableLongFormatEnum {
  HOURLY = 'HH:mm',
  WEEKLY = 'eeee, dd.MM',
  MONTHLY = 'dd.LL.yyyy',
  YEARLY = 'LLLL',
  TEN_YEARS = 'yyyy',
}
