import { DeviceConnectionState } from '../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../hooks';
import { ChannelCoverStateInterface, ChannelGateStateInterface, PublishStatusRest } from '../../../types';
import { DeviceResponse } from '../device/device.types';

interface Hook {
  setChannelAsDisconnected: (channelId: string, data: DeviceResponse) => void;
  resetCalibrateState: (channelId: string, data: DeviceResponse) => void;
}

export const useUpdateChannel = (): Hook => {
  const { setChannelState } = useChannelsState();

  const setChannelAsDisconnected = (channelId: string, data: DeviceResponse) => {
    if (
      data.publishStatus === PublishStatusRest.DeviceDisconnected ||
      data.publishStatus === PublishStatusRest.ContractNotSupported ||
      data.publishStatus === PublishStatusRest.DeviceOrChannelNotFound
    ) {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        newState[channelId] = {
          ...newState[channelId],
          deviceConnectionState: DeviceConnectionState.Disconnected,
        };

        return newState;
      });
    }
  };

  const resetCalibrateState = <T extends ChannelCoverStateInterface | ChannelGateStateInterface>(
    channelId: string,
    data: DeviceResponse,
  ) => {
    if (data.publishStatus === PublishStatusRest.Ok) {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        const calibrateState = (newState[channelId] as T).calibrateState;

        newState[channelId] = {
          ...newState[channelId],
          calibrateState: {
            openingMilliseconds: calibrateState?.openingMilliseconds || 0,
            closingMilliseconds: calibrateState?.closingMilliseconds || 0,
            error: calibrateState?.error,
            progress: calibrateState?.progress,
            started: false,
          },
        } as T;

        return newState;
      });
    }
  };

  return {
    setChannelAsDisconnected,
    resetCalibrateState,
  };
};
