import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { aggregatedParameters } from '../../../../../measurement/utils';
import './index.scss';

export const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');

  if (active && payload?.length) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload && (
          <div style={{ textAlign: 'center', width: '100%' }}>{payload[0].payload.tooltipTime}</div>
        )}
        {payload[0].payload.values.map((el) => (
          <div key={el.type} className="tooltip__element">
            <span style={{ color: el.color }}>{`${t(`energyMeasurements.types.summary.${el.type}`)}: `}</span>
            <span>{el.value ? `${el.value.toFixed(3)}${aggregatedParameters[el.type].unit}` : tc('noData')}</span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};
